import { Observable, map } from 'rxjs';
import { isNullOrUndefined, throwError } from '../shared/util';
import { CustomValue } from '../entities/custom-value';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JourneyService } from './journey.service';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class CustomValueService {

  constructor(
    private http: HttpClient,
    private journeyService: JourneyService
  ) { }

  getCustomValue(customValueID: string): Observable<CustomValue | null> {
    const journeyID = this.journeyService.getNonNullableJourney().journeyID;
    return this.http.get<CustomValue>(`/api/journeycustomvalues/${journeyID}/${customValueID}/latest`);
  }
  /**
  * Get custom value. If the custom value or the value of the custom value is null, throw an error.
  */
  getNonNullableCustomValue(customValueID: string): Observable<CustomValue> {
    return this.getCustomValue(customValueID)
      .pipe(map(x => {
        if (isNullOrUndefined(x)) {
          throwError(`The custom value with customValueID "${customValueID}" is null. It cannot be null.`);
        }

        if (isNullOrUndefined(x.value)) {
          throwError(`The custom value with customValueID "${customValueID}" has a null value property. It cannot be null.`);
        }
        return x;
      }));
  }

  saveCustomValues(customValues: CustomValue[]): Observable<CustomValue[]> {
    return this.http.post<CustomValue[]>("/api/journeycustomvalues/", customValues);
  }

  createCustomValues(customValueID: string, value?: string): CustomValue {
    return {
      journeyID: this.journeyService.getNonNullableJourney().journeyID,
      created: moment().utc().toDate(),
      lastModified: moment().utc().toDate(),
      customValueID,
      value
    };
  }
}